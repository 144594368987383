@layer modules, ui, base;
@layer ui {
  /** @define FieldContainer */

.fieldContainer_jds-FieldContainer__ZIUSU {
  display: block;
  position: relative;
  min-width: 0; /* reset default overflow content behavior of a fieldset */
  margin: 0 0 var(--spacer-200);
  padding: 0;
  border: none;
  color: var(--color-black);
  font-size: var(--normalBody);
}

/* stylelint-disable plugin/selector-bem-pattern */
.fieldContainer_jds-FieldContainer--disabled__s1R2l .fieldContainer_jds-Field__notRequiredText__gIF9c {
  color: inherit;
}
/* stylelint-enable */

.fieldContainer_jds-FieldContainer__switchText__Tbcki {
  padding-top: var(--spacer-50);
  font-weight: var(--normal);
}

}
@layer ui {
  /** @define Field */

.field_jds-Field__label__2NFTo {
  display: block;
  margin-bottom: var(--spacer-50);
  font-size: var(--body2);
  font-weight: var(--semiBold);
  line-height: var(--body2-lh);
}

.field_jds-Field__label--isResized__93_ei {
  transform: translateY(6px);
  font-size: var(--minorBody);
  line-height: var(--minorBodyLineHeight);
}

.field_jds-Field__label--disabled__zlX5R {
  color: var(--color-grey--200);
}

.field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--600);
  font-weight: var(--normal);
}

.field_jds-Field__notRequiredText__bfS7o::before {
  content: "-";
  margin: 0 var(--spacer-50);
}

.field_jds-Field__label--disabled__zlX5R .field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--200);
}

.field_jds-Field__inputContainer__OUGsz {
  position: relative;
  width: 100%;
  min-height: var(--field-height);
}

.field_jds-Field__iconWrapper__1YDn6 {
  position: absolute;
  top: 50%;
  right: var(--spacer-200);
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-black);
}

.field_jds-Field__iconWrapper--left__vGTS1 {
  right: auto;
  left: var(--spacer-200);
}

.field_jds-Field__iconWrapper--disabled__WshVl {
  color: var(--color-grey--200);
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl) {
  color: var(--color-grey--200);
  cursor: pointer;
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl):hover {
  color: var(--color-black);
}

.field_jds-Field__icon__YwksS {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

}
@layer ui {
  /** @define IndicatorContainer */

/* stylelint-disable plugin/selector-bem-pattern */

.IndicatorContainer_jds-IndicatorContainer__kraPj {
  display: flex;
  align-items: center;
}

.IndicatorContainer_jds-IndicatorContainer__tag__vDO_T {
  display: flex;
  align-items: center;
  padding: 0 var(--spacer-100) 0 var(--spacer-150);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--100);
  color: var(--color-black);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
}

.IndicatorContainer_jds-IndicatorContainer__remove__DKZ_B {
  box-sizing: initial;
  padding: var(--spacer-50);
}

.IndicatorContainer_jds-IndicatorContainer__tag--disabled__1Sm7b {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey {
    display: none;
  }
}

@media (max-width: 63.999rem) {
  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey .IndicatorContainer_jds-IndicatorContainer__tag__vDO_T {
    background-color: transparent !important;
    color: var(--color-black) !important;
  }

  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey .IndicatorContainer_jds-IndicatorContainer__tag__vDO_T > span {
    display: none;
  }
}

}
@layer ui {
  /** @define Checkbox */

.checkbox_jds-Checkbox__tX9yi {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  color: var(--color-black);
  font-weight: var(--normal);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.checkbox_jds-Checkbox--disabled__dPiKi {
  color: var(--color-grey--200);
  cursor: default;
}

.checkbox_jds-Checkbox__input__Ekrqr {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox_jds-Checkbox__checker__7o3_v {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-100);
  background-color: var(--color-white);
}

.checkbox_jds-Checkbox__checker--withLabel__DMeay {
  margin-right: var(--spacer-100);
}

.checkbox_jds-Checkbox__tX9yi:hover > .checkbox_jds-Checkbox__input__Ekrqr:not(:disabled) + .checkbox_jds-Checkbox__checker__7o3_v,
.checkbox_jds-Checkbox__input__Ekrqr:focus + .checkbox_jds-Checkbox__checker__7o3_v {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker__7o3_v {
  border-color: var(--color-grey--100);
  background-color: var(--color-grey--100);
  cursor: default;
}

.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker--checked__SKul9,
.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker--indeterminate__vgewT {
  border-color: var(--color-grey--100);
  background-color: var(--color-grey--100);
}

.checkbox_jds-Checkbox__checker--checked__SKul9,
.checkbox_jds-Checkbox__checker--indeterminate__vgewT {
  border-color: var(--themeMainColor, var(--color-black));
  background-color: var(--themeMainColor, var(--color-black));
}

.checkbox_jds-Checkbox__checker--indeterminate__vgewT::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  border: 1px solid var(--color-white);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Icon"].checkbox_jds-Checkbox__tick__PLYM5 {
  position: absolute;
  width: 15px;
  height: 15px;
  stroke-width: 1px;
  stroke: var(--color-white);
  fill: var(--color-white);
}

}
@layer ui {
  /** @define Option */

.option_jds-Option___XgfL {
  /* stylelint-disable-next-line value-no-vendor-prefix */ /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: var(--normalBody);
}

.option_jds-Option__wrapper__21_Kn {
  display: flex;
}

.option_jds-Option__icon__RFr69 {
  visibility: hidden;
  box-sizing: content-box;
  padding: var(--spacer-50) 0 0 var(--spacer-100);
}

.option_jds-Option__icon--selected__LvQui {
  visibility: visible;
}

.option_jds-Option__outer__LNa4o:hover .option_jds-Option__TickBox__N8QqG {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.option_jds-Option__wrapper--grouped__LPeFX {
  margin-left: calc(var(--spacer-200) * -1);
}

}
@layer ui {
  /** @define MenuList */

.MenuList_jds-MenuList__block__Henbt:not(:last-child) {
  border-bottom: 1px solid var(--color-grey--100);
}

.MenuList_jds-MenuList__tagBlock__z_yUo {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacer-100) var(--spacer-200);
  background: var(--color-white);
}

.MenuList_jds-MenuList__footer__TsAdN {
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid var(--color-grey--100);
  background: var(--color-white);
}

}
@layer ui {
  /** @apply CellApply */

.CellApply_jds-CellApply__5ywvC {
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-100) var(--spacer-200);
}

}
@layer ui {
  /** @define ValueContainer */

/* stylelint-disable plugin/selector-bem-pattern */

.ValueContainer_jds-ValueContainer__ZtCHV {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  gap: var(--spacer-50) !important;
}

.ValueContainer_jds-ValueContainer__ZtCHV[class*="jds-Select__value-container--has-value"][class*="jds-Select__value-container--is-multi"] {
  padding: 0 var(--spacer-50) 0 var(--spacer-200) !important;
}

.ValueContainer_jds-ValueContainer--isEmpty___8nnc {
  color: var(--color-grey--500);
}

.ValueContainer_jds-ValueContainer--isDisabled__2_RPo {
  color: var(--color-grey--200);
}

.ValueContainer_jds-ValueContainer__ZtCHV.ValueContainer_jds-ValueContainer--withIcon__PWihS svg {
  color: var(--color-black);
}

.ValueContainer_jds-ValueContainer__displayedValue__fEccl {
  position: relative;
  width: 100%;
}

.ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  content: attr(data-placeholder);
  position: absolute;
  top: 50%;
  max-width: 90%;
  margin-left: var(--spacer-50);
  overflow: hidden;
  transform: translateY(-50%);
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.ValueContainer_jds-ValueContainer--isFocused__pKGdI .ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  content: none;
}

.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  margin-left: var(--spacer-100);
}

.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .jds-Select__placeholder,
.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .jds-Select__multi-value {
  display: none !important;
}

}
@layer ui {
  /** @define CellInformation */

.CellInformation_jds-CellInformation__xhI5D {
  padding: var(--spacer-150) var(--spacer-200);
  color: var(--color-grey--600);
}

}
@layer ui {
  /** @define Image */

/* Legacy variables used in other repositories */
/* stylelint-disable plugin/selector-bem-pattern */

.image_jds-Image__MmccF {
  --image-box-tiny: 24px;
  --image-box-small: 40px;
  --image-box-medium: 64px;
  --image-box-big: 80px;
  --image-box-large: 128px;
}

.image_jds-Image__box--tiny__1MfO2 {
  width: var(--image-box-tiny);
  height: var(--image-box-tiny);
}

.image_jds-Image__box--small__9_Eyw {
  width: var(--image-box-small);
  height: var(--image-box-small);
}

.image_jds-Image__box--medium__ykp0v {
  width: var(--image-box-medium);
  height: var(--image-box-medium);
}

.image_jds-Image__box--big__b2oxK {
  width: var(--image-box-big);
  height: var(--image-box-big);
}

.image_jds-Image__box--large__zSe2O {
  width: var(--image-box-large);
  height: var(--image-box-large);
}

.image_jds-Image__box--circle__K3tNB {
  border-radius: var(--radius-900);
}

.image_jds-Image__box--undistorted__VcE43 {
  -o-object-fit: cover;
     object-fit: cover;
}

}
@layer ui {
  /** @define Illustration */
.Illustration_jds-Illustration--big__I2Ypf {
  height: 320px;
}

.Illustration_jds-Illustration--small__QCVX_ {
  height: 200px;
}

}
@layer ui {
  /** @define Link */

.link_jds-Link__IVm1_ {
  color: var(--color-black);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.link_jds-Link__IVm1_:hover {
  color: var(--color-black);
  text-underline-offset: 2px;
  -webkit-text-decoration: underline 2px;
          text-decoration: underline 2px;
}

.link_jds-Link__IVm1_:focus {
  outline: thin auto var(--color-grey--500);
  outline-offset: 4px;
  color: var(--color-black);
}

.link_jds-Link--button__gHSKU {
  cursor: pointer;
}

.link_jds-Link--minor__dX853 {
  font-size: var(--smallBody);
}

.link_jds-Link--line-height-edito__UWwuK {
  line-height: var(--editoBodyLineHeight);
}

.link_jds-Link--line-height-normal__zGWL1 {
  line-height: var(--normalBodyLineHeight);
}

.link_jds-Link--line-height-small__B_Djg {
  line-height: var(--smallBodyLineHeight);
}

.link_jds-Link--line-height-minor__5ahPx {
  line-height: var(--minorBodyLineHeight);
}

.link_jds-Link--reset__2EcM6,
.link_jds-Link--reset__2EcM6:hover,
.link_jds-Link--reset__2EcM6:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

}
@layer ui {
  /* @define Tag */

.Tag_jds-Tag__S_kl6 {
  --Tag-radius: var(--radius-200);
  /* Needed here to prevent calc() with 0 (without unit) which doesn't work. */
  /* stylelint-disable-next-line length-zero-no-unit */
  --Tag-removable-button-width: 0rem;
  /* medium by default */
  --Tag-horizontal-spacing: var(--spacer-150);
  --Tag-vertical-spacing: var(--spacer-50);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: var(--Tag-vertical-spacing) var(--Tag-horizontal-spacing);
  border-radius: var(--Tag-radius);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.Tag_jds-Tag--small__645vS {
  --Tag-horizontal-spacing: var(--spacer-100);
  --Tag-vertical-spacing: 0;

  font-size: var(--smallBody);
}

.Tag_jds-Tag--large__bg77j {
  --Tag-horizontal-spacing: var(--spacer-200);
  --Tag-vertical-spacing: var(--spacer-100);
}

.Tag_jds-Tag--medium__svQfh,
.Tag_jds-Tag--large__bg77j {
  font-size: var(--normalBody);
}

.Tag_jds-Tag--withIcon__zJH9U {
  padding-left: var(--spacer-100);
}

.Tag_jds-Tag--removable__KmanL[class*="small"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-small) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="medium"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-medium) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="large"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-big) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL {
  /* margin-left + Remove icon size */
  padding-right: calc(var(--spacer-50) + var(--Tag-removable-button-width));
}

.Tag_jds-Tag__Icon__XzZfg {
  margin-right: var(--spacer-100);
}

.Tag_jds-Tag__Label__WNaZc {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Tag_jds-Tag__RemoveButton__yNPo2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--Tag-removable-button-width);
  padding-right: var(--spacer-100);
  padding-left: var(--spacer-50);
  border: 0;
  border-top-right-radius: var(--Tag-radius);
  border-bottom-right-radius: var(--Tag-radius);
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.Tag_jds-Tag__RemoveButton__yNPo2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Tag_jds-Tag__RemoveButton__yNPo2:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.Tag_jds-Tag--cctheme__CP2rD .Tag_jds-Tag__RemoveButton__yNPo2 {
  color: var(--color-white);
}

/* @TODO: handle colors variant here */

.Tag_jds-Tag--default__4MLCo {
  background-color: var(--color-grey--100);
  color: var(--color-grey--700);
}

.Tag_jds-Tag--cctheme__CP2rD {
  background-color: var(--mainColor);
  color: var(--color-black);
}

.Tag_jds-Tag--purple__o_4tb {
  background-color: var(--color-purple--100);
  color: var(--color-purple--700);
}

.Tag_jds-Tag--warning__f1lkM {
  background-color: var(--color-warning--100);
  color: var(--color-warning--700);
}

.Tag_jds-Tag--pink__sY_QO {
  background-color: var(--color-pink--100);
  color: var(--color-pink--800);
}

.Tag_jds-Tag--cyan__ZpEK6 {
  background-color: var(--color-cyan--050);
  color: var(--color-cyan--800);
}

.Tag_jds-Tag--yellow__A7uJy {
  background-color: var(--color-yellow--050);
  color: var(--color-yellow--800);
}

.Tag_jds-Tag--dark__7gaeu {
  background-color: var(--color-grey--700);
  color: var(--color-white);
}

}
@layer ui {
  /** @define Body */
/* stylelint-disable plugin/selector-bem-pattern */

.modal_jds-Body--withModal__qnH2p {
  overflow-y: hidden;
}

/** @define Modal */

.modal_jds-Modal__gZSZT {
  --Modal-padding: 1.25rem;
  display: flex;
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*
    Do not switch height to vh unit because behavior is inconsistent on mobile device
    See  https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile and
    https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
    for more informations
  */
  height: 100%;
  overflow: hidden;
  animation: modal_show__fwtTO 0.2s ease-in forwards;
  opacity: 0;
}

.modal_jds-Modal__title__BOiYw {
  font-size: var(--title2);
  font-weight: var(--semiBold);
  line-height: var(--title2-lh);
}

/* Fading overlay */
.modal_jds-Modal__background__3lt8K {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: var(--color-black);
}

[class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
  --Modal-maxWidth: 35.25rem;

  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--Modal-padding);
  overflow-y: auto;
  background-color: var(--color-white);
  color: var(--color-black);
}

.modal_jds-Modal__wrapper--isCentered__b1_g_ {
  justify-content: center;
}

.modal_jds-Modal__wrapper--withIllustration__Oq7X2 {
  align-items: center;
  overflow-y: auto;
}

[class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
  padding: 0;
}

.modal_jds-Modal__header__ClF7_ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.modal_jds-Modal__header--withIllustration__Ud_rK {
  align-items: center;
}

.modal_jds-Modal__header--withoutIcon__fzjpw {
  max-width: 90%;
}

[class*="jds-Modal"].modal_jds-Modal__header--stickyFooter__icmJv {
  padding-top: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

.modal_jds-Modal__closeButton__D01W9 {
  position: absolute;
  z-index: 1; /* in front of the Header */
  top: var(--Modal-padding);
  right: var(--Modal-padding);
  padding: 0;
  border: none;
  background: none;
  color: var(--color-black);
  cursor: pointer;
}

.modal_jds-Modal__footer__VnXGC {
  display: inline-flex;
  flex-flow: column-reverse wrap;
  justify-content: flex-end;
  gap: var(--spacer-200);
}

[class*="jds-Modal"].modal_jds-Modal__footer--sticky__UHRRb {
  position: sticky;
  bottom: 0;
  padding: calc(var(--Modal-padding) / 1.5);
  background-color: #ffffff;
  box-shadow: var(--minor-shadow);
}

.modal_jds-Modal__footer--withIllustration__MmxZl {
  align-items: center;
}

.modal_jds-Modal__body___hKfJ {
  position: relative; /* keep it to improve react-select behavior on modal. No clue, but it's better with it */
  padding: var(--spacer-300) 0;
}

.modal_jds-Modal__body--withIllustration__62YIo {
  height: auto;
  padding-top: var(--spacer-100);
  overflow-y: initial;
}

[class*="jds-Modal"].modal_jds-Modal__body--stickyFooter__uOW8j {
  padding-right: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

/* stylelint-disable-next-line -- force center alignment. */
.modal_jds-Modal__body--withIllustration__62YIo > * {
  align-items: center;
  text-align: center;
}

@media (min-width: 48rem) {
  [class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
    --Modal-padding: var(--spacer-400);
    max-width: var(--Modal-maxWidth);
    /* limit height on desktop to prevent the Modal from overflowing */
    height: auto;
    max-height: 90vh;
    padding: var(--Modal-padding);
    border-radius: var(--radius-400);
  }

  .modal_jds-Modal__footer__VnXGC {
    flex-direction: row;
  }

  [class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
    padding: 0;
  }
}

@keyframes modal_show__fwtTO {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

}
@layer ui {
  /** @define Select */

/* we set global as workaround to post css over static class names
   further more we add jds-Select class to ensure css property priority */

.select_jds-Select__wigeC {
  --Select-innerHeight: calc(var(--field-height) - 2px); /* -2px for border */
  box-sizing: initial;
  flex: 1;
  width: 100%;
  font-size: var(--normalBody);
}

.select_jds-Select--hasError__ks179 .jds-Select__input:focus {
  border-color: transparent !important;
}

.select_jds-Select__wigeC .jds-Select__value-container {
  padding: 0 0 0 var(--spacer-200) !important;
}

.select_jds-Select__wigeC .jds-Select__control {
  min-height: var(--field-height);
  overflow: hidden;
  transition: none;
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  cursor: pointer;
}

.select_jds-Select__wigeC .jds-Select__control--is-focused.jds-Select__control--menu-is-open .jds-Select__single-value {
  /* Text or images of text that are part of an inactive user interface component have no contrast requirement */
  /* https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html */
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__control:hover {
  border-color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control:hover,
.select_jds-Select__wigeC .jds-Select__control--is-focused {
  outline: 2px solid var(--color-grey--500) !important; /* !important to surcharge internal library use of !important :/ */
  outline-offset: -1px;
}

.select_jds-Select--hasError__ks179 .jds-Select__control {
  border-color: var(--color-error--500);
}

.select_jds-Select--hasError__ks179 .jds-Select__control:hover,
.select_jds-Select--hasError__ks179 .jds-Select__control--is-focused {
  outline-color: var(--color-error--500) !important; /* !important to surcharge internal library use of !important :/ */
}

.select_jds-Select__wigeC .jds-Select__placeholder,
.select_jds-Select__wigeC .jds-Select__single-value,
.select_jds-Select__wigeC .jds-Select__input {
  margin: 0;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.select_jds-Select__wigeC .jds-Select__placeholder {
  overflow: hidden;
  color: var(--color-grey--500);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select_jds-Select__wigeC .jds-Select__single-value,
.select_jds-Select__wigeC .jds-Select__multi-value__label {
  /* stylelint-disable-next-line value-no-vendor-prefix */ /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  max-width: none;
  overflow: hidden;
  text-overflow: initial;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.select_jds-Select__wigeC .jds-Select__single-value {
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__menu {
  z-index: 2; /* Because label is 1 */
  overflow: hidden;
  border-radius: var(--radius-200);
  box-shadow: var(--default-shadow);
}

.select_jds-Select__wigeC .jds-Select__menu-list {
  max-height: 264px;
  padding: 0;
  border-bottom: 1px solid var(--color-grey--100);
}

.select_jds-Select__wigeC .jds-GroupedCheckbox .jds-Select__option {
  padding-left: calc(var(--spacer-200) * 2);
}

.select_jds-Select__wigeC .jds-Select__option {
  padding: var(--spacer-150) var(--spacer-200);
  background-color: var(--color-white);
  color: var(--color-black);
  font-weight: var(--normal);
}

.select_jds-Select__wigeC .jds-Select__option--is-selected {
  color: var(--color-black) !important;
}

.select_jds-Select__wigeC .jds-Select__option--is-focused,
.select_jds-Select__wigeC .jds-Select__option:hover,
.select_jds-Select__wigeC .jds-Select__option:focus {
  background-color: var(--color-grey--025);
}

.select_jds-Select__wigeC .jds-Select__input-container {
  flex: 0 1 auto;
  margin: 0;
  overflow: auto;
}

.select_jds-Select__wigeC .jds-Select__indicators {
  align-items: normal;
}

.select_jds-Select__wigeC .jds-Select__indicator {
  display: flex;
  align-items: center;
  height: var(--Select-innerHeight);
  padding: var(--spacer-150);
  transition: none;
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__clear-indicator,
.select_jds-Select__wigeC .jds-Select__loading-indicator {
  padding-right: calc(var(--spacer-150) / 2);
}

.select_jds-Select__wigeC .jds-Select__dropdown-indicator {
  padding-right: var(--spacer-200);
  padding-left: calc(var(--spacer-150) / 2);
}

.select_jds-Select__wigeC .jds-Select__indicator:hover {
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__option--is-disabled,
.select_jds-Select__wigeC .jds-Select__option--is-disabled:hover {
  background-color: transparent;
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
}

.select_jds-Select__wigeC .jds-Select__single-value--is-disabled {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled .jds-Select__indicator {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled .jds-Select__placeholder {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__group {
  padding: 0;
}

.select_jds-Select__wigeC .jds-Select__group-heading {
  margin: 0;
  padding: var(--spacer-300) var(--spacer-200) var(--spacer-50) var(--spacer-200);
  background-color: var(--color-white);
  color: var(--color-grey--600);
  font-size: var(--smallBody);
  font-weight: var(--normal);
  text-transform: none;
}

/* ******************************** */
/* MultiValue */
/* ******************************** */

.select_jds-Select__wigeC .jds-Select__multi-value {
  margin: var(--spacer-50);
  padding: 0 var(--spacer-100) 0 var(--spacer-150);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__multi-value__label {
  padding: 0;
  color: var(--black);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
}

.select_jds-Select__wigeC .jds-Select__multi-value__remove {
  padding: var(--spacer-50);
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__multi-value__remove:hover {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
}

.select_jds-Select__wigeC .jds-Select__value-container--is-multi {
  max-height: 82px; /* 82 is ~2.5 line of tags */
  overflow: auto;
}

.select_jds-Select__wigeC .jds-Select__value-container--is-multi.jds-Select__value-container--has-value {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled {
  opacity: 0.5;
}

.select_jds-Select__wigeC .jds-Select__multi-value.jds-Select__multi-value--is-disabled {
  background-color: var(--color-grey--100);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled .jds-Select__multi-value__label {
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled .jds-Select__multi-value__remove {
  color: var(--color-grey--200);
}

.select_jds-Select__linkedPictoWrapper__xRFYQ {
  --Select-linkedPictoSize: 32px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.select_jds-Select__linkedPicto__JC3kh {
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--field-height);
  height: var(--field-height);
  margin-right: var(--spacer-100);
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-200);
  background: var(--color-white);
}

.select_jds-Select__wigeC:hover + .select_jds-Select__linkedPicto__JC3kh:not(.select_jds-Select__linkedPicto--disabled__ynUtu),
.select_jds-Select__wigeC:focus-within + .select_jds-Select__linkedPicto__JC3kh:not(.select_jds-Select__linkedPicto--disabled__ynUtu) {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.select_jds-Select__wigeC:hover + .select_jds-Select__linkedPicto__JC3kh.select_jds-Select__linkedPicto--error__zfSPl,
.select_jds-Select__wigeC:focus-within + .select_jds-Select__linkedPicto__JC3kh.select_jds-Select__linkedPicto--error__zfSPl {
  outline-color: var(--color-error--500);
}

.select_jds-Select__linkedPicto--disabled__ynUtu {
  background-color: var(--color-grey--025);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.select_jds-Select__linkedPicto--disabled__ynUtu > .select_jds-Select__linkedPicto--disabled__ynUtu {
  filter: grayscale(1);
  opacity: 0.3;
}

.select_jds-Select__linkedPicto--error__zfSPl:not(.select_jds-Select__linkedPicto--disabled__ynUtu) {
  border-color: var(--color-error--500);
}

.select_jds-Select__linkedPictoImg__mDw7_ {
  -o-object-fit: contain;
     object-fit: contain;
  width: var(--Select-linkedPictoSize);
  height: var(--Select-linkedPictoSize);
}

.select_jds-Select--noInteractionBorder__Bj57_ .jds-Select__control:hover,
.select_jds-Select--noInteractionBorder__Bj57_ .jds-Select__control--is-focused {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

}
@layer ui {
  /** @define CellCTA */

.CellCTA_jds-CellCTA__60Ck_ {
  padding: var(--spacer-150) var(--spacer-200);
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
@layer ui {
  .sk-Typography_main__I32O9 {
  margin: unset;
  padding: unset;
}

/**
 * Tokens seems wrong for font families and weights but they are not.
 * The naming constraint came from Token Studio in Figma part where tokens are
 * generated. Token Studio creates as many tokens as there are variants.
 * For example, if we have the following configuration:
 * 3 hierarchy variants
 * 3 weights variants
 * 2 font families
 * Token Studio will create: 3 * 3 * 2 = 18 tokens.
 * Instead, we can handle variants separately and have only 3 + 3 + 2 = 8 tokens.
 * In our case, all Text class names use:
 * - the same font family
 * - the same font weight according to weight variant
 *   (regardless of the font used: body1, body2 etc.).
 */

/**
 * Font families
 */

.sk-Typography_title1__kpKKc,
.sk-Typography_title2___x7oz,
.sk-Typography_display1__01oEX,
.sk-Typography_display2__CnKww {
  font-family: var(--sk-typography-title-1-font-family), helvetica, arial, sans-serif;
}

.sk-Typography_body1__rvFYv,
.sk-Typography_body2__W0CNH,
.sk-Typography_title3__c40PS,
.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-family: var(--sk-typography-body-1-regular-font-family), helvetica, arial, sans-serif;
}

/**
 * Weights
 */

.sk-Typography_regular__a_y2X {
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.sk-Typography_semiBold__OC7cU {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
}

.sk-Typography_bold__ICdus {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

/**
 * @FIXME: title token has been moved (.title1 uses --…-title-2) for mobile handling.
 *   See with @valentinmichel-jt for what needs to be done to make it more maintainable.
 *   N.B. .title4 is not handled yet and, in this case, it will miss the --…-title-5 token.
 *        We'll also have an n+1 missing token for each title variant added.
 */

.sk-Typography_title1__kpKKc {
  font-size: var(--sk-typography-title-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-2-line-height);
  text-decoration: none;
}

.sk-Typography_title2___x7oz {
  font-size: var(--sk-typography-title-3-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-3-line-height);
  text-decoration: none;
}

.sk-Typography_title3__c40PS {
  font-size: var(--sk-typography-title-4-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-4-line-height);
  text-decoration: none;
}

.sk-Typography_body1__rvFYv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-decoration: none;
}

.sk-Typography_body2__W0CNH {
  font-size: var(--sk-typography-body-2-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-decoration: none;
}

.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-size: var(--sk-typography-caption-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-caption-1-line-height);
  text-decoration: none;
}

.sk-Typography_caption1Underline__eZKaw {
  text-decoration: underline;
}

.sk-Typography_display1__01oEX {
  font-size: var(--sk-typography-display-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-1-line-height);
  text-decoration: none;
}

.sk-Typography_display2__CnKww {
  font-size: var(--sk-typography-display-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-2-line-height);
  text-decoration: none;
}

@media (min-width: 768px) {
  .sk-Typography_title1__kpKKc {
    font-size: var(--sk-typography-title-1-font-size);
    line-height: var(--sk-typography-title-1-line-height);
  }

  .sk-Typography_title2___x7oz {
    font-family: var(--sk-typography-title-2-font-family), helvetica, arial, sans-serif;
    font-size: var(--sk-typography-title-2-font-size);
    font-weight: var(--sk-typography-title-2-font-weight);
    line-height: var(--sk-typography-title-2-line-height);
  }

  .sk-Typography_title3__c40PS {
    font-size: var(--sk-typography-title-3-font-size);
    line-height: var(--sk-typography-title-3-line-height);
  }
}

}
